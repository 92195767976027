<template>
  <custom-modal
    v-if="organisation.emAccessStatus"
    v-model="isModalOpen"
    :title="$t(`organisation.moderation.${organisation.emAccessStatus}.title`)"
    size="max-w-[375px]"
    hide-footer
  >
    <div
      class="flex gap-[10px] items-center p-[22px] border-[2px] rounded-[12px] mb-[22px]"
      :class="[COLORS_BY_STATUS[organisation.emAccessStatus].textBlockBackgroundColor, COLORS_BY_STATUS[organisation.emAccessStatus].textBlockBorder]"
    >
      <div
        class="p-[7px] rounded-full"
        :class="COLORS_BY_STATUS[organisation.emAccessStatus].iconBackgroundColor"
      >
        <nuxt-icon
          :name="ICON_VARIANT[organisation.emAccessStatus]"
          class="text-[24px] text-white"
        />
      </div>
      <span class="font-semibold text-[1.15rem] leading-tight">{{ $t(`organisation.moderation.${organisation.emAccessStatus}.text`) }}</span>
    </div>

    <div
      class="flex justify-between items-center p-[22px] rounded-[12px] bg-primary-light text-[18px] font-semibold mb-[22px]"
    >
      <span class="text-secondary"> {{ $t('organisation.form.id.label') }} </span>
      <span> {{ organisation.id }} </span>
    </div>

    <div
      class="p-[22px] rounded-[12px] bg-primary-light font-semibold mb-4"
    >
      <h6 class="text-[18px] mb-4">
        {{ $t('organisation.moderation.contacts.label') }}
      </h6>
      <div class="flex items-center mb-4">
        <nuxt-icon
          name="entities/whats-app-circle"
          class="text-[38px] mr-[10px]"
          filled="filled"
        />
        <a
          :href="`https://wa.me/${contactNumber}`"
          target="_blank"
          class="font-medium"
        >
          {{ formatIncompletePhoneNumber(contactNumber) }}
        </a>
      </div>

      <div class="flex items-center mb-4">
        <nuxt-icon
          name="entities/telegram-circle"
          class="text-[38px] mr-[10px]"
          filled="filled"
        />
        <a
          :href="`https://t.me/${telegramNickname}`"
          target="_blank"
          class="font-medium"
        >
          {{ telegramNickname }}
        </a>
      </div>

      <div class="flex items-center">
        <div class="mr-[10px] p-[7px] rounded-full bg-white">
          <nuxt-icon
            name="common/telephone-not-filled"
            class="text-[24px]"
          />
        </div>
        <a
          :href="`tel:${contactNumber}`"
          target="_blank"
          class="font-medium"
        >
          {{ formatIncompletePhoneNumber(contactNumber) }}
        </a>
      </div>
    </div>
    <auth-btn-logout class="mx-auto" />
  </custom-modal>

</template>

<script setup lang="ts">

import CustomModal from '~/ui/modals/CustomModal.vue'
import { formatIncompletePhoneNumber } from 'libphonenumber-js'
import { ORGANISATION_ACCESS_STATUS } from '~/common/constants/organisation/Organisation.accessStatus'
import { Organisation } from '~/common/types/organisation/Organisation'
import AuthBtnLogout from '~/modules/auth/components/AuthBtnLogout.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  organisation: {
    type: Object as PropType<Organisation>,
    required: true,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void }>()

const ICON_VARIANT = {
  [ORGANISATION_ACCESS_STATUS.denied]: 'common/x-circle',
  [ORGANISATION_ACCESS_STATUS.onModeration]: 'common/warning-triangle',
}

const COLORS_BY_STATUS = {
  [ORGANISATION_ACCESS_STATUS.denied]: {
    textBlockBorder: 'border-danger',
    textBlockBackgroundColor: 'bg-danger-light',
    iconBackgroundColor: 'bg-danger',
  },
  [ORGANISATION_ACCESS_STATUS.onModeration]: {
    textBlockBorder: 'border-warning',
    textBlockBackgroundColor: 'bg-warning-light',
    iconBackgroundColor: 'bg-warning',
  },
}

const contactNumber = '+995 577 94 38 26'
const telegramNickname = '@EstatyxRealty'

const isModalOpen = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})
</script>
